"use client";

import Link from "next/link";
import { useEffect } from "react";

import Layout from "@components/layout";

export default function Error({ error, reset }: { error: Error; reset: () => void }) {
	useEffect(() => {
		// Log the error to an error reporting service
		console.error(error);
	}, [error]);
	return (
		<Layout>
			<div className="py-20 ml-20 text-left">
				<h2 className="text-4xl font-bold mb-10">Något gick fel vid inladdningen.</h2>

				<button
					onClick={
						// Attempt to recover by trying to re-render the segment
						() => reset()
					}
				>
					Försök igen
				</button>

				<Link href="/" className="block my-4">
					<button className="btn btn-outline btn-ghost capitalize gap-2">
						<i className="ri-arrow-left-s-line"></i> Till startsidan
					</button>
				</Link>
			</div>
		</Layout>
	);
}
